export class ashcorp_field_select_type {
    label: string;
    value: any;
    group: any;

    constructor(label: string, value: any = null, group: any = null) {
        this.label = label;
        this.value = value ?? label;
        this.group = group;
    }
}