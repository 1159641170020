<div class="grid grid-cols-10 gap-4" style="padding-top:16px;">
    <div [class]="'mat-error col-span-'+ size">
        <div class="flex items-center mr-4" *ngIf="status">
            <ng-container *ngIf="status === 'loading'">
                <mat-icon class="text-green-500" svgIcon="heroicons_outline:refresh"></mat-icon>
                <span class="ml-2 text-green-500" [innerHTML]="message"></span>
            </ng-container>
            <ng-container *ngIf="status === 'success'">
                <mat-icon class="text-green-500" svgIcon="heroicons_outline:check"></mat-icon>
                <span class="ml-2 text-green-500" [innerHTML]="message"></span>
            </ng-container>
            <ng-container *ngIf="status === 'error'">
                <mat-icon class="text-red-500" svgIcon="heroicons_outline:x"></mat-icon>
                <span class="ml-2 text-red-500" [innerHTML]="message"></span>
            </ng-container>
        </div>
    </div>
    <ashcorp-button *ngIf="deletable" class="col-span-1" (click)="this.delete.emit();" color="accent">Delete</ashcorp-button>
    <ashcorp-button *ngIf="cancelable" class="col-span-1" (click)="this.cancel.emit();" color="warn">Cancel</ashcorp-button>
    <ashcorp-button *ngIf="saveable" class="col-span-1" (click)="this.save.emit();" color="primary">Save</ashcorp-button>
</div>