import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

import { ashcorp_button_component } from 'app/components/ashcorp-button/ashcorp-button.component';
import { ashcorp_field_date_component } from 'app/components/ashcorp-field/ashcorp-field-date/ashcorp-field-date.component';
import { ashcorp_field_select_component } from 'app/components/ashcorp-field/ashcorp-field-select/ashcorp-field-select.component';
import { ashcorp_field_text_component } from 'app/components/ashcorp-field/ashcorp-field-text/ashcorp-field-text.component';
import { ashcorp_field_button_component } from 'app/components/ashcorp-field/ashcorp-field-button/ashcorp-field-button.component';
import { ashcorp_field_seperator_component } from 'app/components/ashcorp-field/ashcorp-field-seperator/ashcorp-field-seperator.component';
import { ashcorp_field_number_component } from 'app/components/ashcorp-field/ashcorp-field-number/ashcorp-field-number.component';
import { ashcorp_field_currency_component } from 'app/components/ashcorp-field/ashcorp-field-currency/ashcorp-field-currency.component';
import { ashcorp_field_textarea_component } from 'app/components/ashcorp-field/ashcorp-field-textarea/ashcorp-field-textarea.component';
import { ashcorp_field_month_component } from 'app/components/ashcorp-field/ashcorp-field-month/ashcorp-field-month.component';
import { ashcorp_field_file_component } from 'app/components/ashcorp-field/ashcorp-field-file/ashcorp-field-file.component';
import { ashcorp_table_mini_component } from 'app/components/ashcorp-table/ashcorp-table-mini/ashcorp-table.mini.component';
import { ashcorp_carousel_thumbnail_component } from 'app/components/ashcorp-carousel/ashcorp-carousel-thumbnail/ashcorp-carousel-thumbnail.component';
import { ashcorp_table_component } from 'app/components/ashcorp-table/ashcorp.table.component';
import { ashcorp_field_checkbox_component } from 'app/components/ashcorp-field/ashcorp-field-checkbox/ashcorp-field-checkbox.component';
import { ashcorp_field_selection_component } from 'app/components/ashcorp-field/ashcorp-field-selection/ashcorp-field-selection.component';
import { ashcorp_form_button_component } from 'app/components/ashcorp-form/ashcorp-form-buttons/ashcorp-form-button.component';


@NgModule({
    declarations: [
        ashcorp_button_component,

        ashcorp_field_text_component,
        ashcorp_field_textarea_component,
        ashcorp_field_number_component,
        ashcorp_field_currency_component,
        ashcorp_field_date_component,
        ashcorp_field_month_component,
        ashcorp_field_select_component,
        ashcorp_field_selection_component,
        ashcorp_field_button_component,
        ashcorp_field_file_component,
        ashcorp_table_mini_component,
        ashcorp_table_component,
        ashcorp_field_seperator_component,
        ashcorp_field_checkbox_component,
        ashcorp_carousel_thumbnail_component,
        ashcorp_form_button_component
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslocoModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatExpansionModule,
        MatTooltipModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ashcorp_button_component,
        ashcorp_field_text_component,
        ashcorp_field_textarea_component,
        ashcorp_field_number_component,
        ashcorp_field_currency_component,
        ashcorp_field_date_component,
        ashcorp_field_month_component,
        ashcorp_field_select_component,
        ashcorp_field_selection_component,
        ashcorp_field_button_component,
        ashcorp_field_file_component,
        ashcorp_table_mini_component,
        ashcorp_table_component,
        ashcorp_field_seperator_component,
        ashcorp_field_checkbox_component,
        ashcorp_carousel_thumbnail_component,
        ashcorp_form_button_component
    ],
    providers: [

    ]
})
export class SharedModule {
}