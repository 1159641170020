<div class="grid grid-cols-2 gap-4">
    <ng-container [formGroup]="_form">
        <mat-form-field>
            <mat-label *ngIf="show">Search</mat-label>
            <input matInput formControlName="search" placeholder="Search" #input>
        </mat-form-field>
    </ng-container>

    <ng-container [formGroup]="form">
        <mat-form-field>
            <mat-label *ngIf="show" [innerHTML]="label"></mat-label>
            <mat-select [formControlName]="name">
                <mat-option *ngIf="create" [value]="0">New {{label}}</mat-option>
                <mat-option *ngFor="let option of selections ?? [];" [value]="option?.value" [innerHTML]="option?.label"></mat-option>
            </mat-select>
            <mat-error *ngIf="show && form?.get(name)?.hasError('required')">
                {{label}} is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </ng-container>
</div>