import { FormBuilder, FormControl } from "@angular/forms";

export type api_status_type = "success" | "error";
export enum api_status {
    success = "success",
    error = "error",
    loading = "loading",
    blank = "",

    deleted = 0,
    active = 1
}

export type api_code_type = 200 | 400 | 401 | 404 | 500 | 502;
export enum status_class_color {
    info = "text-blue",
    ok = "text-green",
    disabled = "text-grey",
    error = "mat-error",
    warning = "text-yellow"
}
export enum substatus {
    Flagged = "Flagged"
}

export class api_response<type> {
    code: api_code_type;
    title: string;
    status: api_status;
    message: string;
    payload: type;
    pagination: pagination_type;
}

export class pagination_type {
    length: number = 5;
    size: number = 5;
    page: number = 0;
    lastPage: number;
    options: Array<number> = [5, 10, 25, 100]
    sort: string = "id";
    direction: "asc" | "desc" | "" = "asc";
}
export class table_header_type {
    label?: string = "";
    class?: string = "";
    format?: string = "";
}

export class Resolver_Item<type> {
    key: string;
    value: type;
}

export class KVPair<type> {
    key: string = "";
    value: type = null;

    constructor(key: string, value: type) {
        this.key = key;
        this.value = value;
    }

    static forms(
        _form_builder: FormBuilder,
        value: KVPair<string>[]
    ) {
        let result = _form_builder.array([]);
        (value ?? []).forEach((group: KVPair<string>) => {
            result.push(KVPair.form(_form_builder, group));
        });
        return result;
    }

    static form(_form_builder: FormBuilder, value: KVPair<string>) {
        return _form_builder.group(
            {
                key: new FormControl(value.key),
                value: new FormControl(value.value)
            }
        );
    }
}

export class KTVPair<keytype, type> {
    key: keytype;
    value: type;
}

