import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { api_status } from 'app/shared/shared.types';

@Component({
  selector: 'ashcorp-form-button',
  templateUrl: './ashcorp-form-button.component.html',
  animations: fuseAnimations
})

export class ashcorp_form_button_component implements AfterViewInit {
  @Input() message: string = "";
  @Input() status: api_status = api_status.blank;

  @Input() saveable: boolean = true;
  @Input() deletable: boolean = true;
  @Input() cancelable: boolean = true;

  @Output() delete = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  public size = 10;

  constructor() { }

  ngAfterViewInit(): void {
    this.size -= this.saveable ? 1 : 0;
    this.size -= this.deletable ? 1 : 0;
    this.size -= this.cancelable ? 1 : 0;
  }
}
