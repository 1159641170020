<div *ngIf="init" class="absolute inset-0 flex flex-col min-w-0 overflow-hidden bg-card dark:bg-transparent">
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden">

            <ng-container *ngIf="item_count > 0; else noRows">

                <div class="overflow-x-auto sm:overflow-y-auto" cdkScrollable>

                    <table class="w-full bg-transparent" [ngClass]="{'pointer-events-none': loader}" mat-table matSort [matSortActive]="pagination.sort" [matSortDisableClear]="true" [matSortDirection]="pagination.direction" [multiTemplateDataRows]="true" [dataSource]="data_source$" [trackBy]="track_by">

                        <ng-container *ngFor="let header of header" [matColumnDef]="header">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef class="bg-gray-50 dark:bg-black dark:bg-opacity-5" [ngClass]="headers$[header].class ?? ''" style="cursor: pointer;">
                                <div class="mr-2 font-medium text-sm whitespace-nowrap"
                                     [ngClass]="(headers$[header]?.class ?? '') + ' ' + (row?.class ?? '')"
                                     [innerHTML]="headers$[header].label ?? header">
                                </div>
                            </th>

                            <td mat-cell *matCellDef="let row; let i = dataIndex;">
                                <div class="mr-2 font-medium text-sm whitespace-nowrap"
                                     [ngClass]="(headers$[header]?.class ?? '') + ' ' + (row?.class ?? '')"
                                     [innerHTML]="headers$[header].format ? format(headers$[header], row[header]) : row[header]">
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef class="bg-gray-50 dark:bg-black dark:bg-opacity-5"> </th>
                            <td class="pr-8" mat-cell *matCellDef="let item">
                                <ng-container *ngIf="init">
                                    <button *ngIf="detailed" class="min-w-10 min-h-7 h-7 px-2 mx-2 leading-6" mat-stroked-button (click)="toggle_details(item.id)" matTooltip="Show details">
                                        <mat-icon class="icon-size-5" [svgIcon]="item_selected?.id === item.id ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'"></mat-icon>
                                    </button>
                                    <ng-container *ngFor="let action of actions">
                                        <button *ngIf="access(action.access) && action.show(item)" class="min-w-10 min-h-7 h-7 px-2 mx-2 leading-6" mat-stroked-button [matTooltip]="action.tooltip"
                                                (click)="emit_action(action, item);" [ngClass]="action.class ?? 'mat-stroked-button'">
                                            <mat-icon *ngIf="action.icon" class="icon-size-5" [svgIcon]="action.icon"></mat-icon>
                                            <span *ngIf="action.label" [innerHTML]="action.label"> </span>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="item_details">
                            <td class="p-0 border-b-0" mat-cell *matCellDef="let item" [attr.colspan]="columns.length + 1">
                                <div class="shadow-lg overflow-hidden" [@expandCollapse]="item_selected?.id === item.id ? 'expanded' : 'collapsed'">
                                    <ng-container *ngIf="item_selected?.id === item.id">
                                        <ng-content *ngIf="item_selected?.id === item.id"></ng-content>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>

                        <tr class="shadow" mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                        <tr class="h-18 hover:bg-hover" mat-row *matRowDef="let item; columns: columns;"></tr>
                        <tr class="h-0" mat-row *matRowDef="let row; columns: ['item_details']"></tr>

                    </table>

                </div>
                <mat-paginator
                               class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                               [ngClass]="{'pointer-events-none': loader}"
                               [length]="pagination.length"
                               [pageIndex]="pagination.page"
                               [pageSize]="pagination.size"
                               [pageSizeOptions]="pagination.options ?? [5,10,20,50,100]"
                               [showFirstLastButtons]="true">
                </mat-paginator>
            </ng-container>

            <ng-template #noRows>
                <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There are no {{name}} records found!</div>
            </ng-template>

        </div>
    </div>
</div>