import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { FormArray } from '@angular/forms';
import { BehaviorSubject, catchError, Observable, of, shareReplay, tap } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { navigation_service } from 'app/core/navigation/navigation.service';
import { api_response } from './shared.types';
import { ashcorp_field_files_type } from 'app/components/ashcorp-field/ashcorp-field-file/ashcorp-field-file.types';

@Injectable({
    providedIn: 'root'
})
export class shared_service {
    constructor(
        public _http_client: HttpClient,
        private _authService: AuthService,
        private _userService: UserService,
        protected _navigation_service: navigation_service
    ) { }

    private _search_key: BehaviorSubject<string> = new BehaviorSubject(null);
    get search_key$(): Observable<string> {
        return this._search_key.asObservable();
    }
    sKey(key: string): Observable<string> {
        if (key.length != 0) {
            this._search_key.next(key);
        }
        return this.search_key$;
    }

    tText(key: string, type: string = '') {
        if (this._authService.is_admin) {
            if (type != "") {
                type = "/" + type;
            }
            return environment.api_url + "manager/translate/" + key + type;
        }
        return "#";
    }

    tCKey(key: string, prefix: string = '') {
        prefix = this.slugify(prefix);
        key = this.slugify(key).replace(prefix + "_", "");
        return [prefix, key].join("_");
    }

    tClass() {
        return this._authService.is_admin;
    }

    tLog(x: any) {
        console.log(x);
    }



    FormArray(fa: any) {
        return fa as FormArray;
    }




    two_dp(value: number): string {
        if (isNaN(value)) value = 0;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }

    abs(number: number): number {
        return Math.abs(number);
    }

    slugify(text: string, join: string = "_") {
        return text.toLowerCase().split(" ").join(join).split("-").join(join).split("&").join(join);
    }

    unslugify(text: string, join: string = "_") {
        return text.split(join).join(" ").split("-").join(" ").replace(/(\w)(\w*)/g,
            function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
    }

    zero_or_modulo(number: number, base: number = 10) {
        return (number == 0) || (number % base == 0);
    }

    range(end: number, start: number = 0, step: number = 1) {
        return (Array.from({ length: end + 1 - start }, (_, i) => i + step - 1 + start));
    }

    is_active(value: string) {
        return value == 'ACTIVE';
    }

    guid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
            const random = Math.random() * 16 | 0;
            const value = char === 'x' ? random : (random & 0x3 | 0x8);
            return value.toString(16);
        });
    }

    //UPLOAD
    public uploaded_files_key(label: string): string {
        return this.tCKey(label, "File");
    }
    private _uploaded_files: Array<BehaviorSubject<object>> = [];
    public uploaded_files$(label: string): Observable<object> {
        let key = this.uploaded_files_key(label);
        if (!this._uploaded_files[key]) {
            this._uploaded_files[key] = new BehaviorSubject<object>(null);
        }
        return this._uploaded_files[key].asObservable();
    }
    upload_files(label: string, files: File[], type: string): void {
        let key = this.uploaded_files_key(label);
        const form_data = new FormData();
        for (let idx = 0; idx < files.length; idx++) {
            form_data.append(`files_${idx}`, files[idx]);
        }
        form_data.append('access_token', localStorage.getItem('access_token'));
        form_data.append('company_code', this._navigation_service.company.code);
        form_data.append('branch_code', this._navigation_service.branch.code);
        form_data.append('type', type);
        this._http_client.post('api/document/upload', form_data)
            .pipe(
                catchError((response: HttpErrorResponse) => of(response.error)),
                tap((result: api_response<ashcorp_field_files_type>) => {
                    if (result?.payload) {
                        if (result?.status == "success") {
                            this._uploaded_files[key].next(new ashcorp_field_files_type(result.payload, "success"));
                        } else {
                            this._uploaded_files[key].next(new ashcorp_field_files_type(result.payload, "error"));
                        }
                    } else {
                        this._uploaded_files[key].next(new ashcorp_field_files_type([], "error", "File could not be uploaded"));
                    }
                }),
                shareReplay(1)
            ).subscribe();
    }
    upload_clear(label: string) {
        let key = this.uploaded_files_key(label);
        this._uploaded_files[key].next(null);
    }
}