import { AfterContentInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { shared_service } from 'app/shared/shared.service';
import { ashcorp_field_select_type } from '../ashcorp-field-select/ashcorp-field-select.types';

@Component({
  selector: 'ashcorp-field-selection',
  templateUrl: './ashcorp-field-selection.component.html'
})
export class ashcorp_field_selection_component implements AfterContentInit {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() service: any;
  @Input() source: string;
  @Input() search: string;
  @Input() create: string = null;
  @Input() show = true;

  get source$(): Observable<any[]> {
    return this.service[this.source];
  }
  get search$(): any {
    return this.service[this.search];
  }

  public selections: ashcorp_field_select_type[];
  public _form: FormGroup;

  constructor(
    public _form_builder: FormBuilder
  ) {
    this._form = this._form_builder.group({ search: new FormControl("") });
  }

  ngAfterContentInit(): void {
    this.source$.subscribe(
      (options: any[]) => {
        if (options) {
          this.selections = [];
          options.forEach(
            (option: any) => {
              this.selections.push(new ashcorp_field_select_type(option.name, option.id));
            }
          );
        }
      }
    );

    this._form.get("search").valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        (search: string) => {
          this.search$.next(search);
        }
      );

    this.form.get(this.name).valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(
        (id: number) => {
          if (id == 0) {
            this.service
              .create()
              .subscribe(
                (item: any) => {
                  if (item) {
                    this.form.get(this.name).setValue(item.id);
                    this.search$.next("");
                  }
                }
              )
          }
        }
      );

    this.search$.next("");
  }
}
